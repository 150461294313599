export const backendUrl = import.meta.env.VITE_BACKEND_URL;
// export const DEBUG = import.meta.env.VITE_DEBUG === 'true';
export const DEBUG = false;
export const ENABLE_LOGGING = import.meta.env.VITE_ENABLE_LOGGING === "true";
export const FACEBOOK_APP_ID = "937522511109972";
export const ANDROID_DOWNLOAD_LINK = "https://play.google.com/store/apps/details?id=us.inyc.app"
export const IOS_DOWNLOAD_LINK = "https://apps.apple.com/us/app/in-yc/id6473464078"

export function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "ios";
  }

  return "unknown";
}

export const version = "2.2.20";
